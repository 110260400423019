/* Center dots */
.ant-carousel .slick-dots {
    bottom: -20px;
  }
  
  /* Style dots */
  .ant-carousel .slick-dots li button {
    background: #999;
  }
  
  .ant-carousel .slick-dots li.slick-active button {
    background: #333;
  }
  

  /* Customize the scrollbar width */
.custom-scrollbar {
  overflow-y: auto;
}

/* Target scrollbar in WebKit browsers (Chrome, Safari, etc.) */
.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-500); /* Thumb color */
  border-radius: 30px;
}

/* Optional: for Firefox */
.custom-scrollbar {
  // scrollbar-width: thin;
  scrollbar-color: var(--color-primary-500); /* Thumb color Track color */
}
.ant-card-body{
  padding: 5px!important;
}
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;