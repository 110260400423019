/* Change background color of the active radio button */
.ant-radio-button-wrapper-checked {
    background-color: #4caf50; /* Set your desired active tab background color */
    color: white; /* Optional: Change text color for contrast */
  }

  /* Optional: Add hover effect */
  .ant-radio-button-wrapper:hover {
    background-color: #e8f5e9; /* Set a hover color if desired */
  }

  .ant-card-body{
    width: 100%;
    height: 100%;
  }
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;