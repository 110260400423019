/* Add this CSS to your styles */
.custom-slider .ant-slider-track {
    height: 30px; /* Adjust the track height */
    bottom: -10px!important;
  }
  
  .custom-slider .ant-slider-handle::before{
    height: 30px!important;
    top: -9px!important;
    width: 0px!important;
    left: 6px;
  }
  .custom-slider .ant-slider-handle::after{
    height: 30px!important;
    top: -9px!important;
    width: 0px!important;
    left: 6px;

  }
  // .custom-slider .ant-slider-rail {
  //   height: 10px; /* Adjust the rail height */
  // }
  
  // .custom-slider .ant-slider-handle {
  //   width: 20px; /* Increase the handle width */
  //   height: 20px; /* Increase the handle height */
  //   margin-top: -6px; /* Center the handle */
  // }
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;